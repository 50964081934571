@charset "UTF-8";
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700|Roboto+Slab:300,400,700');

html, body {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  padding: 0;
}

header {
  border-bottom: 1px solid #f3f3f3;
  padding: 1rem;
  background-color: white;
}
header.-fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}
header > * {
  max-width: 1024px;
  margin: 0 auto;
  font-size: 1.5rem;
  font-weight: 700;
  color: #333;
  text-align: center;
}

.wrap-content {
  padding-top: 80px;
  max-width: 1024px;
  margin: 0 auto;
}

.article {
  padding: 20px;
}

.version {
  margin: 1.5rem 0;
}

.version .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;
  border-bottom: 1px solid #f3f3f3;
}

.version-num {
  font-size: 1.25rem;
  color: #333;
  font-weight: 600;
}

.datetime {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.9rem;
}

.label {
  display: inline-flex;
  padding: 4px 10px;
  color: white;
  background: #2196F3;
  border-radius: 12px;
  font-size: 0.8rem;
  font-weight: 600;
}

.label-orange {
  display: inline-flex;
  padding: 4px 10px;
  color: white;
  background: #FF9800;
  border-radius: 12px;
  font-size: 0.8rem;
  font-weight: 600;
}

.label-inline {
  padding: 2px 6px;
  font-size: 0.75rem;
  color: #FF9800;
  border: 1px solid #FF9800;
  background: transparent;
  border-radius: 5px;
  margin-right: 5px;
  font-weight: 600;
}

.label-inline-wip {
  padding: 2px 6px;
  font-size: 0.75rem;
  color: #b160f7;
  border: 1px solid #954cfb;
  background: transparent;
  border-radius: 5px;
  margin-right: 5px;
  font-weight: 600;
}

.label-inline-done {
  padding: 2px 6px;
  font-size: 0.75rem;
  color: #12ab2e;
  border: 1px solid #1bf94b;
  background: rgb(223, 249, 223);
  border-radius: 5px;
  margin-right: 5px;
  font-weight: 600;
}

ul {
  list-style-position: inside;
  padding-left: 1.5rem;
  margin-top: 5px;
}

ul li {
  margin: 5px 0;
  font-size: 0.95rem;
  color: #555;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


[data-color-scheme="dark"] {
  color-scheme: dark;
}

[data-color-scheme="light"] {
  color-scheme: light;
}

.scroll::-webkit-scrollbar {
  width: 7px; /* width of the entire scrollbar */
}

.scroll::-webkit-scrollbar-track {
  background: #30303000; /* color of the tracking area */
}

.scroll::-webkit-scrollbar-thumb {
  background-color: rgb(70, 70, 70); /* color of the scroll thumb */
  border-radius: 8px; /* roundness of the scroll thumb */
}

/*=============== DARK THEME ===============*/

body.dark-theme {
  /*Estilos del sidebarMenu.jsx*/
  --first-color: #1e1f22;
  --background-icons: #2d2e30;
  --background-theme-icons: #3f424a;
  --button-background-icons: #64666d;

  /*Estilos de la pantalla del chat cuando esta vacia archivo EmptyState.jsx*/
  --icon-empty: #343434;
  --text-tittle-empty: #343541;
  --background-messages-empty: #f9fafb;
  --text-messages-empty: #545454;
}